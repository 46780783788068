import { Headers } from "./styles";
import MenuIcon from "@mui/icons-material/Menu";
import SVG from "components/SVG";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { HandleOpenDrawer, Overlay } from "redux/slices/auth";
import { GetUser } from "utits/auth";

function Header() {
  const dispatch = useDispatch();
  const { openDrawer } = useSelector((state) => state.auth);
  const user = GetUser();
  const wrapperRef = useRef();
  const [transparent, setTransparent] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleUserScrolling);
    return () => {
      window.removeEventListener("scroll", handleUserScrolling);
    };
  }, [transparent]);

  const handleUserScrolling = () => {
    if (wrapperRef && wrapperRef.current) {
      const BOUNDARY_OF_TRANSPARANT_BG = wrapperRef.current.clientHeight;
      const scrollPosition = document.documentElement.scrollTop;
      if (scrollPosition > BOUNDARY_OF_TRANSPARANT_BG) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    }
  };

  return (
    <>
      <Headers.Container bg={transparent ? transparent : ""} ref={wrapperRef}>
        <Link to="/">
          <SVG name="vfc"></SVG>
        </Link>
        {user ? (
          <>
            <Headers.IdAccount>
              {user?.reward < 6 && <SVG name={`reward${user?.reward}`} />}
              {/* <span style={{ textTransform: "uppercase", marginLeft: "12px" }}> */}
              <span style={{ textTransform: "uppercase" }}>
                {user?.username?.length > 8
                  ? user?.username.substring(0, 9) + "..."
                  : user?.username}{" "}
              </span>
            </Headers.IdAccount>
            {/* <Headers.Avatar>
                            <SVG name='avatar' />
                        </Headers.Avatar>*/}
            {/* <Headers.Logout onClick={logout}>
              LOG OUT <LogoutIcon />
            </Headers.Logout> */}
          </>
        ) : (
          <>
            <Headers.ButtonSecond onClick={() => dispatch(Overlay("login"))}>
              sign in
              <SVG name="signIn" />
            </Headers.ButtonSecond>
            <Headers.ButtonSecond>
              <NavLink to="/auth/register">sign up</NavLink>
            </Headers.ButtonSecond>
          </>
        )}
        <Headers.ButtonMenu
          onClick={() => dispatch(HandleOpenDrawer(!openDrawer))}
        >
          <MenuIcon style={{ color: "white" }}></MenuIcon>
        </Headers.ButtonMenu>
      </Headers.Container>
    </>
  );
}
export default Header;
