/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { getSession } from "utits/auth";

const API = axios.create({ baseURL: process.env.REACT_APP_PORT });
// const REACT_UPLOAD_FILE = "https://now-file-test.tocuna.com/";
const REACT_CREATE_USDT = "https://swzapiwebv5.toearnnow.com:4099/";
const REACT_UPLOAD_FILE = "https://apinewnew.toearnnow.com/";
const REACT_BUY_NFT = "https://swzapiver3nowapissx.toearnnow.com/";
// const REACT_BUY_NFT = "http://192.168.1.29:8081";
API.interceptors.request.use((req) => {
  if (localStorage.getItem("user")) {
    req.headers.sessionID = `${getSession()} `;
  }
  return req;
});

export const req12Character = (data) =>
  API.post("api/characters/get12Characters", data);
export const req12CharacterTurn = (data) => {
  API.post("api/characters/turn12Characters", data);
};

export default {
  CreateApiBuyNft: (url, data, session) => {
    if (session) {
      session = getSession();
    }

    return axios(`${REACT_BUY_NFT}${url} `, {
      headers: {
        sessionID: `${session} `,
      },

      method: "POST",
      data,
    });
  },

  CreateApiUpload: (url, data, session) => {
    if (session) {
      session = getSession();
    }

    return axios(`${REACT_UPLOAD_FILE}${url} `, {
      headers: {
        sessionID: `${session} `,
        "Content-Type": "multipart/form-data",
      },

      method: "POST",
      data,
    });
  },

  CreateApiS: (url, data, session) => {
    if (session) {
      session = getSession();
    }

    return axios(`${process.env.REACT_APP_PORT}${url} `, {
      headers: {
        sessionID: `${session} `,
        "Content-Type": "multipart/form-data",
      },

      method: "POST",
      data,
    });
  },
  CreateApi1: (url, data, session) => {
    if (session) {
      session = getSession();
    }

    return axios(`${process.env.REACT_APP_PORT}${url} `, {
      headers: {
        sessionID: `${session} `,
      },

      method: "POST",
      data,
    });
  },
  CreateApiSS: (url, data) => {
    return axios(`${process.env.REACT_APP_PORT}${url} `, {
      method: "POST",
      data,
    });
  },

  CreateApiSSS: (url, session) => {
    if (session) {
      session = getSession();
    }
    return axios(`${process.env.REACT_APP_PORT}${url} `, {
      headers: {
        sessionID: `${session} `,
      },
      method: "GET",
    });
  },

  CreateApiPOST: (url, session) => {
    if (session) {
      session = getSession();
    }
    return axios(`${process.env.REACT_APP_PORT}${url} `, {
      headers: {
        sessionID: `${session} `,
      },
      method: "POST",
    });
  },
  CreateApiGET: (url) => {
    return axios(`${process.env.REACT_APP_PORT}${url} `, {
      method: "GET",
    });
  },
  CreateWallet: (url, session) => {
    if (session) {
      session = getSession();
    }

    return axios(`${REACT_BUY_NFT}${url}`, {
      headers: {
        sessionID: `${session}`,
      },
      method: "GET",
    });
  },

  CreateWalletUSDT: (url, data, session) => {
    if (session) {
      session = getSession();
    }
    return axios(`${REACT_CREATE_USDT}${url}`, {
      headers: {
        sessionID: `${session} `,
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      data,
    });
  },
};
