/* eslint-disable react/jsx-pascal-case */
import {
  listHeader,
  ListMegaMenu,
  ListMegaMenuChildren,
  ListMegaMenuChildrenMb,
} from "./Data";
import {
  IconClose,
  IconMenu,
  Item,
  Logo,
  MegaMenu,
  Menu,
  MenuMoblie,
  Nav,
  NavMoblie,
  NavMoblieItem,
  NavMoblieItemChildren,
  OverlayScreen,
  Span,
  StyledButton,
  StyledButtonMoblie,
  StyledHeader,
} from "./Header.styled";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Image from "assets/svg/vfc.svg";
import { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { GetUser } from "utits/auth";

function Header() {
  const wrapperRef = useRef();
  const [menu, setMenu] = useState(false);
  const [transparent, setTransparent] = useState(false);
  const navigate = useNavigate();
  const user = GetUser();
  const location = useLocation();

  const handleMenuMoblie = () => {
    setMenu(!menu);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleUserScrolling);
    return () => {
      window.removeEventListener("scroll", handleUserScrolling);
    };
  }, [transparent]);

  const handleUserScrolling = () => {
    if (wrapperRef && wrapperRef.current) {
      const BOUNDARY_OF_TRANSPARANT_BG = wrapperRef.current.clientHeight;
      const scrollPosition = document.documentElement.scrollTop;
      if (scrollPosition > BOUNDARY_OF_TRANSPARANT_BG) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    }
  };

  let [megaMenu, setMegaMenu] = useState(false);
  let handleOnMouseEnter = (e) => {
    if (e === "About") setMegaMenu(e);
  };
  let handleOnMouseLeave = (e) => {
    setMegaMenu("");
  };

  let [megaMenuChildren, setMegaMenuChildren] = useState("");
  let [megaMenuChildren1, setMegaMenuChildren1] = useState("");

  let handleOnMouseEnterChildren = (e) => {
    if (e === "ECOSYSTEM") setMegaMenuChildren(e);
    if (e === "Ecosystem") setMegaMenuChildren(e);
    if (e === "Blockchain" || e === "Moblie Ad platform")
      setMegaMenuChildren1(e);
    if (
      megaMenuChildren === "Ecosystem" &&
      e !== "Moblie Ad platform" &&
      e !== "Blockchain"
    ) {
      setMegaMenuChildren("");
    }
  };
  let handleOnMouseLeaveChildren = (e) => {
    setMegaMenuChildren("");
  };
  return (
    <>
      {menu && <OverlayScreen onClick={() => setMenu(!menu)} />}
      <StyledHeader
        bg={menu ? menu : transparent}
        ref={wrapperRef}
        megaMenu={megaMenu}
      >
        <Container style={{ height: "100%" }}>
          <Nav>
            <Link to={"/"}>
              {" "}
              <Logo src={Image} />
            </Link>
            <Menu>
              {" "}
              {listHeader?.map((e, i) => (
                <>
                  <Span
                    key={i}
                    bg={menu ? menu : transparent}
                    active={location.pathname == e.link}
                    onMouseEnter={() => handleOnMouseEnter(e.title)}
                    onMouseLeave={() => handleOnMouseLeave(e.title)}
                  >
                    {e?.link ? (
                      <NavLink to={e?.link}> {e.title} </NavLink>
                    ) : (
                      e?.title
                    )}{" "}
                  </Span>
                </>
              ))}
            </Menu>
            {!user ? (
              <div>
                <StyledButton
                  onClick={() => navigate("/auth/register")}
                  style={{ marginRight: "10px" }}
                >
                  Register
                </StyledButton>
                <StyledButton
                  onClick={() => navigate("/auth/login")}
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(234, 2, 116, 0.3) 0%, rgba(123, 13, 106, 0.3) 100%)",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                  }}
                >
                  Login
                </StyledButton>
              </div>
            ) : (
              <StyledButton
                onClick={() => navigate("/account")}
                style={{ marginRight: "10px" }}
              >
                Dashboard
              </StyledButton>
            )}

            <MenuMoblie onClick={handleMenuMoblie}>
              {!menu ? (
                <IconMenu style={{ color: "white" }} />
              ) : (
                <IconClose style={{ color: "white" }} />
              )}
            </MenuMoblie>
          </Nav>

          <NavMoblie menu={menu && "active"}>
            <NavMoblieItem>
              {listHeader.map((e, i) => (
                <Item key={i} menu={menu && "active"}>
                  {e.link ? (
                    <NavLink to={e?.link} onClick={() => setMenu(!menu)}>
                      {" "}
                      {e?.title}{" "}
                    </NavLink>
                  ) : (
                    e?.title
                  )}
                  {e?.children && (
                    <NavMoblieItemChildren>
                      {ListMegaMenuChildrenMb.map((e, i) => {
                        return (
                          <div>
                            {e?.title === "Roadmap" ? (
                              <a href={`#ROADMAP`}>
                                {" "}
                                <ArrowRightIcon /> {" " + e.title}
                              </a>
                            ) : (
                              <NavLink
                                to={e.link}
                                target={
                                  e?.link === "litepaper.pdf" ? "_blank" : ""
                                }
                                onClick={() =>
                                  handleOnMouseEnterChildren(e.title)
                                }
                              >
                                <ArrowRightIcon /> {e.title}
                              </NavLink>
                            )}
                            {megaMenuChildren === "Ecosystem" &&
                              megaMenuChildren === e.title && (
                                <MegaMenu.childrenMb>
                                  {ListMegaMenuChildren.map((e, i) => {
                                    return (
                                      <div>
                                        <NavLink
                                          to={e.link}
                                          onClick={() =>
                                            handleOnMouseEnterChildren(e.title)
                                          }
                                        >
                                          <ArrowRightIcon /> {e.title}
                                        </NavLink>
                                        {megaMenuChildren1 ===
                                          "Moblie Ad platform" &&
                                          megaMenuChildren1 === e?.title && (
                                            <MegaMenu.childrenMb>
                                              <NavLink to={"#"}>
                                                <ArrowRightIcon /> Planned
                                                development
                                              </NavLink>
                                            </MegaMenu.childrenMb>
                                          )}
                                        {megaMenuChildren1 === "Blockchain" &&
                                          megaMenuChildren1 === e?.title && (
                                            <MegaMenu.childrenMb>
                                              <NavLink to={"#"}>
                                                <ArrowRightIcon /> Planned
                                                development
                                              </NavLink>
                                            </MegaMenu.childrenMb>
                                          )}
                                      </div>
                                    );
                                  })}
                                </MegaMenu.childrenMb>
                              )}
                          </div>
                        );
                      })}
                    </NavMoblieItemChildren>
                  )}
                </Item>
              ))}
              {user ? (
                <StyledButtonMoblie onClick={() => navigate("/account")}>
                  Dashboard
                </StyledButtonMoblie>
              ) : (
                <div>
                  <StyledButtonMoblie
                    onClick={() => navigate("/auth/register")}
                    style={{ marginRight: "10px" }}
                  >
                    Register
                  </StyledButtonMoblie>
                  <StyledButtonMoblie
                    onClick={() => navigate("/auth/login")}
                    style={{
                      background:
                        "linear-gradient(180deg, rgba(234, 2, 116, 0.3) 0%, rgba(123, 13, 106, 0.3) 100%)",
                      border: "1px solid rgba(255, 255, 255, 0.3)",
                      marginTop: "10px",
                    }}
                  >
                    Login
                  </StyledButtonMoblie>
                </div>
              )}
            </NavMoblieItem>
          </NavMoblie>
        </Container>
      </StyledHeader>
      {megaMenu == "About" && (
        <MegaMenu.main
          onMouseEnter={() => handleOnMouseEnter("About")}
          onMouseLeave={() => handleOnMouseLeave("")}
        >
          <Container>
            <MegaMenu.wrapper>
              <MegaMenu.context>
                {ListMegaMenu.map((e, i) => {
                  return (
                    <div
                      onMouseEnter={() => handleOnMouseEnterChildren(e.title)}
                      onMouseLeave={() => handleOnMouseLeaveChildren(e.title)}
                      style={{ position: "relative" }}
                    >
                      {e?.title === "ROADMAP" ? (
                        <a href={`#${e?.title}`}>{e.title}</a>
                      ) : (
                        <NavLink
                          target={e?.link === "litepaper.pdf" ? "_blank" : ""}
                          to={e?.link}
                        >
                          {e.title}
                        </NavLink>
                      )}
                      {megaMenuChildren === "ECOSYSTEM" &&
                        megaMenuChildren === e.title && (
                          <MegaMenu.children
                            onMouseEnter={() =>
                              handleOnMouseEnterChildren("ECOSYSTEM")
                            }
                            onMouseLeave={() =>
                              handleOnMouseLeaveChildren(e.title)
                            }
                          >
                            {ListMegaMenuChildren.map((e, i) => {
                              return (
                                <div>
                                  <NavLink
                                    to={e.link}
                                    onMouseEnter={() =>
                                      handleOnMouseEnterChildren(e.title)
                                    }
                                  >
                                    <ArrowRightIcon /> {e.title}
                                  </NavLink>
                                  {megaMenuChildren1 === "Moblie Ad platform" &&
                                    megaMenuChildren1 === e?.title && (
                                      <MegaMenu.childrenMb>
                                        <NavLink to={"#"}>
                                          <ArrowRightIcon /> Planned development
                                        </NavLink>
                                      </MegaMenu.childrenMb>
                                    )}
                                  {megaMenuChildren1 === "Blockchain" &&
                                    megaMenuChildren1 === e?.title && (
                                      <MegaMenu.childrenMb>
                                        <NavLink to={"#"}>
                                          <ArrowRightIcon /> Planned development
                                        </NavLink>
                                      </MegaMenu.childrenMb>
                                    )}
                                </div>
                              );
                            })}
                          </MegaMenu.children>
                        )}
                    </div>
                  );
                })}
              </MegaMenu.context>
            </MegaMenu.wrapper>
          </Container>
        </MegaMenu.main>
      )}
    </>
  );
}

export default Header;
