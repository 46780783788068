import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import { ErrorMessage, SuccessMessage } from "components/Message";

const initialState = {
  getLoading: false,
  inputWithdraw: "",
  getWallet: "",
  getReward: "",
  getPriceTBC: "",
  getDeposit: "",
  getPriceNow: "",
  getDepositVFC: "",
  getHistoryDeposit: "",
  getHistoryTranfer: [],
  getHistoryWithdraw: [],
  getHistoryReceiver: [],
};

export const handleGetWallet = () => {
  return async (dispatch) => {
    let res = await CreateApi.CreateApiSSS("public/wallet/get-wallets", true);
    dispatch(GetWallet(res?.data?.data));
  };
};

export const handleGetPriceNow = (name) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateWallet(
        `/api/v1/user/master/coin-info?name=NOW`,
        true
      );
      dispatch(GetPriceNow(res?.data?.data));
    } catch (error) {}

    dispatch(GetLoading(false));
  };
};

export const handleCreateSurvey = (data) => {
  return (dispatch) => {
    dispatch(GetLoading(true));

    return new Promise(async (resolve, reject) => {
      try {
        let res = await CreateApi.CreateApiBuyNft(
          `/api/v1/user/survey/create`,
          data,
          true
        );

        resolve(res);
      } catch (error) {
        console.error("Lỗi khi update thông tin khảo sát: ", error);

        reject(error);
      } finally {
        dispatch(GetLoading(false));
      }
    });
  };
};

export const handleGetSurvey = () => {
  return (dispatch) => {
    dispatch(GetLoading(true)); //

    return new Promise(async (resolve, reject) => {
      try {
        let res = await CreateApi.CreateWallet(
          `/api/v1/user/survey/info`,
          true
        );

        resolve(res);
      } catch (error) {
        console.error("Lỗi khi lấy thông tin khảo sát: ", error);

        reject(error);
      } finally {
        dispatch(GetLoading(false));
      }
    });
  };
};

export const handleGetReward = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        "public/block_ico/get-reward",
        true
      );
      dispatch(GetReward(res?.data?.data));
    } catch (error) {}
  };
};

export const handleCreateWallet = (name) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateWallet(
        `api/v1/user/deposit/wallet?code=${name}`,
        true
      );
      dispatch(GetDeposit(res?.data?.data));
    } catch (error) {}

    dispatch(GetLoading(false));
  };
};

export const handleCreateWalletUSDT = (name) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));
    try {
      let res = await CreateApi.CreateWalletUSDT(
        "public/deposit/createwallet",
        { wallet: name },
        true
      );
      dispatch(GetDeposit(res?.data?.data));
    } catch (error) {}

    dispatch(GetLoading(false));
  };
};

export const handleCreateWalletVFC = (name) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS(
        "public/deposit/createwallet-vfc",
        { wallet: name },
        true
      );
      dispatch(GetDepositVFC(res?.data?.data));
    } catch (error) {}

    dispatch(GetLoading(false));
  };
};

export const handleGetPriceTBC = (data) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiSSS(
        "public/block_ico/get-price-nft",
        data,
        true
      );
      if (res?.data?.status === true) {
        dispatch(GetPriceTBC(res?.data?.data));
      } else {
      }
    } catch (error) {
      // ErrorMessage(error.response?.data?.message)
    }
    dispatch(GetLoading(false));
  };
};

export const handleTranfer = (data) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS("public/exchange/claim", data, true);
      if (res?.data?.status === true) {
        SuccessMessage("Tranfer success");
        dispatch(handleGetWallet());
        dispatch(handleHistoryTranfer());
      } else {
        ErrorMessage(res?.data?.message);
      }
    } catch (error) {
      ErrorMessage(error.response?.data?.message);
    }

    dispatch(GetLoading(false));
  };
};

export const handleTranferUser = (data) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS("public/transfer", data, true);
      if (res?.data?.status === true) {
        SuccessMessage("Tranfer success");
        dispatch(handleGetWallet());
        dispatch(handleHistoryTranferUser(1));
      } else {
        ErrorMessage(res?.data?.message);
      }
    } catch (error) {
      ErrorMessage(error?.response?.message);
    }

    dispatch(GetLoading(false));
  };
};

export const handleWithdraw = (data, setStep) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS(
        "public/withdraw/withdraw",
        data,
        true
      );
      if (res?.data?.status === true) {
        setStep(2);
        dispatch(GetLoading(false));
      } else {
        ErrorMessage(res?.data?.message);
        dispatch(GetLoading(false));
      }
    } catch (error) {
      ErrorMessage(error?.response?.message);
      dispatch(GetLoading(false));
    }
  };
};

export const handleVerifyWithdraw = (data, setStep) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS(
        "public/withdraw/confirm-code",
        data,
        true
      );
      if (res?.data?.status === true) {
        SuccessMessage("withdraw success");
        dispatch(handleGetWallet());
        dispatch(handleHistoryWithdraw(1));
        setStep(1);
        dispatch(GetLoading(false));
      } else {
        ErrorMessage(res?.data?.message);
        dispatch(GetLoading(false));
      }
    } catch (error) {
      ErrorMessage(error?.response?.message);
      dispatch(GetLoading(false));
    }
  };
};

export const handleHistoryDeposit = (page) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS(
        "public/deposit/deposithistories",
        {
          limit: 5,
          page: page,
        },
        true
      );
      dispatch(GetHistoryDeposit(res?.data));
    } catch (error) {}

    dispatch(GetLoading(false));
  };
};

export const handleHistoryTranferUser = (page) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS(
        "public/transfer/history",
        {
          limit: 10,
          page: page,
        },
        true
      );
      dispatch(GetHistoryTranfer(res?.data));
    } catch (error) {}

    dispatch(GetLoading(false));
  };
};

export const handleHistoryTranfer = (page) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS(
        "public/exchange/history",
        {
          type: "BONUS",
          limit: 10,
          page: page,
        },
        true
      );
      dispatch(GetHistoryTranfer(res?.data));
    } catch (error) {}

    dispatch(GetLoading(false));
  };
};

export const handleHistoryReceiver = (page) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS(
        "public/transfer/history-receiver",
        {
          limit: 10,
          page: page,
        },
        true
      );
      dispatch(GetHistoryReceiver(res?.data));
    } catch (error) {}

    dispatch(GetLoading(false));
  };
};

export const handleHistoryWithdraw = (page) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS(
        "public/withdraw/histories",
        {
          limit: 5,
          page: page,
        },
        true
      );
      dispatch(GetHistoryWithdraw(res?.data));
    } catch (error) {}

    dispatch(GetLoading(false));
  };
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    GetLoading(state, action) {
      return {
        ...state,
        getLoading: action.payload,
      };
    },
    InputWithdraw(state, action) {
      return {
        ...state,
        inputWithdraw: action.payload,
      };
    },
    GetWallet(state, action) {
      return {
        ...state,
        getWallet: action.payload,
      };
    },
    GetPriceTBC(state, action) {
      return {
        ...state,
        getPriceTBC: action.payload,
      };
    },
    GetReward(state, action) {
      return {
        ...state,
        getReward: action.payload,
      };
    },

    GetPriceNow(state, action) {
      return {
        ...state,
        getPriceNow: action.payload,
      };
    },
    GetDeposit(state, action) {
      return {
        ...state,
        getDeposit: action.payload,
      };
    },
    GetDepositVFC(state, action) {
      return {
        ...state,
        getDepositVFC: action.payload,
      };
    },
    GetHistoryDeposit(state, action) {
      return {
        ...state,
        getHistoryDeposit: action.payload,
      };
    },
    GetHistoryTranfer(state, action) {
      return {
        ...state,
        getHistoryTranfer: action.payload,
      };
    },
    GetHistoryWithdraw(state, action) {
      return {
        ...state,
        getHistoryWithdraw: action.payload,
      };
    },
    GetHistoryReceiver(state, action) {
      return {
        ...state,
        getHistoryReceiver: action.payload,
      };
    },
  },
});

const { reducer, actions } = walletSlice;
export const {
  GetLoading,
  InputWithdraw,
  GetWallet,
  GetReward,
  GetDeposit,
  GetPriceNow,
  GetDepositVFC,
  GetHistoryDeposit,
  GetHistoryTranfer,
  GetHistoryWithdraw,
  GetPriceTBC,
  GetHistoryReceiver,
} = actions;
export default reducer;
