import BannerCountDown from "./BannerCountDown";
import Faq from "./Faq";
import Newspapers from "./Newspapers";
import Trusted from "./Trusted";
import Allocation from "./allocation";
import Banner from "./banner";
import { StyledButton } from "./banner/Banner.Styled";
import Footer from "./footer";
import Header from "./header";
import Roadmap from "./roadmap";
import { Box, Modal, Typography } from "@mui/material";
import bg from "assets/svg/bg-Trusted.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function HomeMain() {
  const [openModal, setOpenModal] = useState(true);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClickHere = () => {
    navigate("/auth/login");
  };

  return (
    <div
      style={{
        background: "#08021c",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Modal thông báo */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(90deg, #780d6957 0%, #ec01744d 100%)",
            padding: 4,
            borderRadius: 3,
            boxShadow: 24,
            width: "100%",
            maxWidth: 600,
            height: "auto",
            animation: "slide-up 1.2s ease-out",
            backdropFilter: "blur(8px)",
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#fff" }}
          >
            Attention toearnnow Users!
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2, color: "#fff" }}>
            <ul>
              <li>
                Convert your holdings to NOW Coin and explore new equity
                opportunities as we gear up for our next big phase!
              </li>
              <li>Don't miss out on this chance to be part of the future.</li>
              <li>Deadline for Conversion: December 23, 2024</li>
              <li>
                Convert Now!
                <span
                  style={{
                    color: "#00bcd4",
                    "&:hover": { color: "#7b1fa2 !important" },
                    cursor: "pointer",
                    textDecoration: "underline",
                    marginLeft: 4,
                  }}
                  onClick={() => handleClickHere()}
                >
                  Click here
                </span>
              </li>
            </ul>
          </Typography>
          <StyledButton>
            <button
              style={{ maxWidth: "none", width: "100%" }}
              onClick={handleCloseModal}
            >
              Close
            </button>
          </StyledButton>
        </Box>
      </Modal>

      {/* Các phần khác của trang */}
      <Header />
      <Banner />
      <Newspapers />
      <Trusted />
      {/* <NftItem /> */}
      <Allocation />
      <Roadmap />
      <BannerCountDown />
      <Faq />
      <Footer />

      <img
        src={bg}
        alt=""
        style={{
          position: "absolute",
          top: "50%",
          left: "-25%",
          width: "60%",
          overflow: "hidden",
          zIndex: "1",
        }}
      />
    </div>
  );
}
